import React, { ComponentType, Suspense, lazy, useEffect } from 'react'
import { useStore } from '../../Hooks/use-store';
import { Route, Routes, useNavigate , useLocation } from 'react-router-dom';
import AuthenticatedLayout from "../../Layout/authenticated";
import Login from "../../pages/login/login";
import Otp from "../../pages/login/otp";
import FirstLoginScreen from "../../pages/login/firstLoginScreen";
import Fulltest from "../../pages/fulltest";
import FullTestWriting from "../../components/fullTestWriting";
import FullTestReading from "../../components/Reading";
import FullTestListening from "../../components/Listening";
import ReadingResults from "../../pages/readingResults";
import ListeningResults from "../../pages/listeningResults";
import Utilities from "../../utilities/index";
import TestView from "../../utilities/TestView";
import SpeakingResults from "../../pages/speakingResults";
import WritingResults from "../../pages/writingResults";
import { styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import HomePageShimmer from '../common/HomePageShimmer';
import OAuthVerificationPage from '../../pages/login/oAuthVerificationPage';
import ErrorPage from '../../pages/errorPage';
import { observer } from 'mobx-react-lite';
import IELTSMarketingUtilities from '../../utilities/IELTSMarketingUtilities';
import RedirectionPage from '../common/RedirectionPage';


/****** LOAD ROUTES ******/
// Set up all routers as lazy routes. THis is important because when the app loads, we want the landing page to split load quickly for SEO purposes.
// Hence make it default to setup all routes as lazy routes.
const Speaking = lazy(() => import("../../pages/speakingMain"));
const Writing = lazy(() => import("../../pages//writingmain"));
const Dashboard = lazy(() => import("../../pages//dashboardmain"));
const Listening = lazy(() => import("../../pages//listeningmain"));
const Reading = lazy(() => import("../../pages//readingmain"));
const ProfilePage = lazy(() => import("../../pages//profilePage"));
const PaymentStatus = lazy(() => import("../../pages//paymentStatus"));
const ProfileMobileView = lazy(() => import("../../pages//profileMobileView"));
const settingsMobileView = lazy(() => import("../../pages//settingsMobileView"));
const editProfileMobileView = lazy(() => import("../../pages//editProfileMobileView"));
const pricingPlansMobileView = lazy(() => import("../../pages//pricingPlansMobileView"));
const SpeakingScoreCaluculations = lazy(() => import("../../pages/speakingScoreCaluculations"));
const WritingScoreCaluculations = lazy(() => import("../../pages/writingScoreCalculations"));
const ReadingScoreCaluculations = lazy(() => import("../../pages/readingScoreCalculations"));
const ListeningScoreCaluculations = lazy(() => import("../../pages/listeningScoreCalculations"));

export const Shellwrapper = ({
    component: Component,
    ...rest
  }: {
    component: ComponentType<any>;
    [key: string]: any;
  }) => {
    const { userStore,dashboardStore } = useStore();
    const {isLoggedIn} = userStore
    const navigate = useNavigate();
    const location = useLocation();
    const signout = () => {
      userStore.clearStoredDate();
      localStorage.clear()
      navigate("/login");
    };
  
    const StyledDialog = styled(Dialog)(({ theme }) => ({
        "& .MuiPaper-root": {
          borderRadius: "16px",
        },
      }));

    useEffect(() => {
      if (!userStore.isLoggedIn) {
        navigate("/login");
        return;
      }
  
      window.addEventListener(
        "token-expired",
        (e) => {
          console.log("Token Expired event triggerred", e);
          signout();
        },
        false
      );
    },[]);
  
    useEffect(() => {
  
      const getRedirectPathName =() => {
        if(userStore.isLoggedIn){
          return `/${userStore.userDashboards[0]?.toLowerCase()}`
        }
        return null
      }
  
      const redirectPathName = getRedirectPathName()
  
      if(redirectPathName && ["/","/otp","/login"].includes(location.pathname)){
        navigate(redirectPathName)
        dashboardStore.setDeleteAlert(true)
      }
  
    },[userStore.isLoggedIn,location.pathname])
  
  
  // useEffect(() => {
  //   window.addEventListener('popstate', (event) => {
  //     // Perform your desired operations here
  //     event.preventDefault();
  //     if (!userStore.isLoggedIn) {
  //       navigate("/login");
  //       return;
  //     }
  //     // if (location.pathname === '/login' || location.pathname === '/otp') {
  //     //     console.log(location.pathname,"INside iFFF")
  //     //     navigate('/speaking');
  //     // }else{
  //     //   console.log(location.pathname,"INside else")
        
  //     // }
  //   });
  
  //   // Clean up the event listener when the component unmounts
  //   return () => window.removeEventListener('popstate', () => {});
  // }, [location, navigate]);
    return (
      <>
        <StyledDialog
        open={dashboardStore.deleteAlert}
        onClose={() => dashboardStore.setDeleteAlert(false)}
      >
        <div className=" w-full  flex flex-col  border border-[#E3E5E9] rounded-2xl ">
         
          <div className="bg-[#FFFFFF] flex flex-col  items-center  p-[1.5rem]">
            <div className="text-center">
               Are you sure you want to <span className='font-semibold text-lg'>Log Out ?</span>
            </div>
            <div className="mt-10 flex">
              <button
                className=" bg-white rounded-full px-4 py-2 text-[#E31837] w-full border border-[#E31837] mr-[1.5rem]"
                onClick={() => {dashboardStore.setDeleteAlert(false)
                    userStore.clearStoredDate()
                    navigate("/login")}}
              >
                Yes
              </button>
              <button
                className=" bg-red-500 rounded-full px-4 py-2 text-white w-full "
                onClick={() =>{
                    console.log("Aredgvbhun ")
                    dashboardStore.setDeleteAlert(false)
                    navigate(`/${userStore.userDashboards[0]?.toLowerCase()}`)
                } }
              >
                No
              </button>
            </div>
          </div>
        </div>
      </StyledDialog>
        {isLoggedIn?<AuthenticatedLayout>
          <Component {...rest} />
        </AuthenticatedLayout>:<Component /> }
      </>
    );
  };
  

const RoutingC = observer(() => {
  const navigate = useNavigate();
  return (
   <Suspense fallback={<Shellwrapper component={HomePageShimmer}/>}>
        <Routes>
          <Route index element={<Shellwrapper component={Login}/>} />
          {/* <Route path="/oauthUserVerification/" element={ <OAuthVerificationPage />} /> */}
          <Route path="/oauthUserVerification/" element={ <OAuthVerificationPage />} />
          <Route path="/login" element={<Shellwrapper component={Login}/>} />
          <Route
            path="/speaking"
            element={<Shellwrapper component={Speaking} />}
          />
          <Route path='/speakingscorecalculations' element={<Shellwrapper component={SpeakingScoreCaluculations}/>}/>
          <Route path="/writing" element={<Shellwrapper component={Writing}/>} />
          <Route path='/writingscorecalculations' element={<Shellwrapper component={WritingScoreCaluculations}/>}/>
          <Route path ="/listening" element={<Shellwrapper component={Listening}/>}/>
          <Route path='/listeningscorecalculations' element={<Shellwrapper component={ListeningScoreCaluculations}/>}/>
          <Route path ="/reading" element={<Shellwrapper component={Reading}/>} />
          <Route path='/readingscorecalculations' element={<Shellwrapper component={ReadingScoreCaluculations}/>}/>
          <Route path ="/dashboard" element={<Shellwrapper component={Dashboard}/>} />
          <Route path = "/profile" element={<Shellwrapper component={ProfilePage}/>} />
          <Route path ="/profileMobileView" element = {<Shellwrapper component={ProfileMobileView}/>} />
          <Route path="/settingsMobileView" element={<Shellwrapper component={settingsMobileView}/>}/>
          <Route path="/pricingPlansMobileView" element={<Shellwrapper component={pricingPlansMobileView}/>}/>
          <Route path="/editProfileMobileView" element={<Shellwrapper component={editProfileMobileView}/>}/>
          <Route path="/otp" element={<Otp/>}/>
          <Route path="/details" element={<FirstLoginScreen />} />
          <Route path="/fulltestwriting" element={<FullTestWriting />} />
          <Route path="/fulltestwriting/part1"   element={<FullTestWriting />} />
          <Route path="/fulltestwriting/part2"  element={<FullTestWriting />}/>
          <Route path="/fulltestreading" element={<FullTestReading />} />
          <Route path="/fulltestreading/part1"   element={<FullTestReading />} />
          <Route path="/fulltestreading/part2"  element={<FullTestReading />}/>
          <Route path="/fulltestreading/part3"  element={<FullTestReading />}/>
          <Route path="/fulltestlistening" element={<FullTestListening />} />
          <Route path="/fulltestlistening/part1"   element={<FullTestListening />} />
          <Route path="/fulltestlistening/part2"  element={<FullTestListening />}/>
          <Route path="/fulltestlistening/part3"  element={<FullTestListening />}/>
          <Route path="/fulltestlistening/part4"  element={<FullTestListening />}/>
          <Route path="/details/:step" element={<FirstLoginScreen />} />
          <Route
            path="/fulltest"
            element={<Shellwrapper component={Fulltest} />}
          />
          <Route
            path="/part1"
            element={<Shellwrapper component={Fulltest} />}
          />
          <Route
            path="/part2"
            element={<Shellwrapper component={Fulltest} />}
          />
          <Route
            path="/part3"
            element={<Shellwrapper component={Fulltest} />}
          />
          <Route
            path="/speakingResults"
            element={<Shellwrapper component={SpeakingResults} />}
          />
          <Route
            path="/writingResults"
            element={<Shellwrapper component={WritingResults} />}
          />
           <Route
            path="/readingResults"
            element={<Shellwrapper component={ReadingResults} />}
          />
           <Route
            path="/listeningResults"
            element={<Shellwrapper component={ListeningResults} />}
          />
           <Route 
            path="/cambri/:isLogin/:token"
            element={<RedirectionPage/>}
          />
          <Route path="/transactionSummary" element={<Shellwrapper component={PaymentStatus} />} />
          <Route path="/utilities" element={<Utilities/>}/>
          <Route path="/operationalutilities" element={<IELTSMarketingUtilities/>}/>
          <Route path="/test/:module/:testId" element={<TestView/>}/>
          <Route path="/unknown" element={<ErrorPage errorMessage={"Possible account issue. Contact support@cambri.ai with all the details"} onBackToHome={() => {navigate("/login")}}/>}/>
        </Routes>
      </Suspense>
  )
})

export default RoutingC