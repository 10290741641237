import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CambriLogo from "../../icons/cambri_logo.png";
import IeltsLogo from "../../icons/Title.png";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { useStore } from "../../Hooks/use-store";
import { UserApi } from "../../api/userapi";

const RedirectionPage = () => {
  const navigate = useNavigate();
  const { isLogin, token } = useParams();
  const { userStore, dashboardStore } = useStore();
  const [loginError, setLoginError] = useState<boolean>();
  const userApi = new UserApi();

  const handleSuccess = async (login: string, authToken: string) => {
    
    try {
      const response : any = await userApi.getSsoLoginToken(authToken);
      if (response?.status === 200) {
        userStore.updateToken(response?.data?.token);
        userStore.updateRefreshToken(response?.data?.refresh_token);
        userStore.isLoggedIn = response.data.already_registered;
        userStore.dashboards = response.data?.dashboard;
        userStore.userDashboards = userStore.decodeDashboards(response.data?.dashboard);
        dashboardStore.showProfilePage(false);
        dashboardStore.updateCurrentNavBar("Speaking");
        let decodedToken : any = jwtDecode(response?.data?.token);
        userStore.studentDetails.mobileNumber = decodedToken?.mobile;
        userStore.updateOperationalUser(decodedToken?.operational);
        toast.success("Login Successful");
        if (decodedToken?.operational) {
          navigate("/operationalutilities");
        } else {
          response.data.already_registered
            ? navigate(`/${userStore.userDashboards[0].toLowerCase()}`)
            : navigate("/details");
          userStore.updateAlreadyRegistered(response.data.already_registered);
        }

        let res = await userApi.loggedInUserApi();
        if (res.status === 200) {
          userStore.isProUser = res.data.is_pro;
          userStore.profilePic = res.data.profile_pic;
          userStore.studentDetails.studentName = res.data.name;
          userStore.studentDetails.emailId = res.data.email;
          userStore.studentDetails.when_Exam = res.data.when_exam_processed;
          userStore.studentDetails.selectedMonth = res.data.when_exam_processed; 
        }else{
          setLoginError(true);
        }
      }else{
        setLoginError(true);
      }
    } catch (err) {
      console.log(err);
      setLoginError(true);
    }
  };

  useEffect(() => {
    if (isLogin && token) {
      handleSuccess(isLogin, token);
    }
  }, [isLogin, token]);

  return (
    <section className="flex justify-center items-center h-screen space-x-10">
      {!loginError ? (
        <>
          <img src={CambriLogo} alt="cambri-logo" className="w-32 h-32" />
          <div className="transform rotate-180">
            <div className="redirect-loading"></div>
          </div>
          <img src={IeltsLogo} alt="IELTS-logo" className="w-36 h-36" />
        </>
      ) : (
        <div className="flex-col">
          <img
            src={IeltsLogo}
            alt="cambri-logo"
            className="w-40 h-40 mx-auto"
          />
          <p className="text-2xl font-avenir text-center text-[#E31837]">
            There seems to be an issue. Please log in again to continue.
          </p>
        </div>
      )}
    </section>
  );
};

export default RedirectionPage;
