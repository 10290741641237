import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import BottomRightIcon from "../../icons/undraw_different_love_a-3-rg 1-right (1).png";
import BottomLeftIcon from "../../icons/undraw_different_love_a-3-rg 1-left.png";
import GoogleImage from "../../icons/google image.svg";
import { useStore } from "../../Hooks/use-store";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-input-2/lib/style.css";
import "../../../src/styles.css";
import { observer } from "mobx-react-lite";
import { OAuthUrl } from "../../api/api-config";
import { toJS } from "mobx";
import { Box, Tab, Tabs } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="w-full flex justify-center items-center"
    >
      {value === index && <Box className="w-full md:w-[80%]" sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


function Login() {
  let navOtp = useNavigate();
  const { userStore, dashboardStore } = useStore();
  const { studentDetails } = userStore;
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onClickEmailHandler = async () => {
    userStore.setIsEmail(true);
    setLoadingEmail(true);
    if (studentDetails.emailId) {
      let student = JSON.parse(JSON.stringify(studentDetails));
      student.emailId = "";
      userStore.updateStudentDetails(student);
      userStore.updateEmailId(studentDetails.emailId);
      try {
        const res = await userStore.request_otp_email(studentDetails.emailId);
        if (res.status === 200) {
          navOtp("/otp");
          setLoadingEmail(false);
        } else {
          setLoadingEmail(false);
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };
  const onClickHandler = async () => {
    userStore.setIsEmail(false);
    if (studentDetails.mobileNumber && isValid) {
      setLoading(true);
      if (studentDetails.mobileNumber) {
        let student = JSON.parse(JSON.stringify(studentDetails));
        student.emailId = "";
        userStore.updateStudentDetails(student);
        userStore.updateMobileNumber(studentDetails.mobileNumber);
        try {
          const res = await userStore.request_otp(studentDetails.mobileNumber);
          if (res.status === 200) {
            navOtp("/otp");
            setLoading(false);
          } else {
            setLoading(false);
          }
        } catch (err) {
          setLoading(false);
        }
      }
    }
  };

  const gmailHandler = () => {
    let redirectURl = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=612878259205-gojd9o52i6om5tnpu2aohbfgln4ikbfb.apps.googleusercontent.com&redirect_uri=${OAuthUrl}&scope=openid%20profile%20email&access_type=offline`;
    window.location.href = redirectURl;
  };

  const handleOnChange = (value: any, country: any) => {
    if (isValidPhoneNumber("+" + value)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    userStore.updateMobileNumber(`+${value}`);
  };

  const handleOnChangeEmail = (e: any) => {
    const email = e.target.value;
    userStore.updateEmailId(email);
    setIsValidEmail(validateEmail(email));
  };

  useEffect(() => {
    // This effect runs only once when the component mounts
    // Disable browser back button
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };

    // Cleanup function
    return () => {
      window.onpopstate = null;
    };
  }, []); // Empty dependency array ensures this effect runs only once

  useEffect(() => {
    userStore.updateMobileNumber("");
    userStore.clearIsLoggedIn();
    userStore.setIsEmail(false);
    userStore.updateEmailId("");
  }, []);
 const [activeTab, setActiveTab] = useState<number>(0);

 const handleTabClick = (index: number) => {
   setActiveTab(index);
 };

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    userStore.updateMobileNumber("");
    userStore.setIsEmail(false);
    userStore.updateEmailId("");
    setValue(newValue);
  };

  return (
    <>
      <div className="bg-[#FFC9C9] p-4 md:p-0 w-full min-h-screen flex justify-center items-center relative ">
        <div className="md:pt-20 md:pb-24 md:px-[120px] p-4 flex flex-col justify-center items-center border rounded-2xl bg-[#FFFFFF] md:w-[50%] w-full max-w-screen-md z-[999]">
          <h1 className="text-[#E31837] text-[40px] font-manrope font-bold tracking-wider text-center mb-11">
            IELTS
          </h1>
          <button
            className="text-[#3C424C] text-2xl font-extrabold text-center mb-10"
            onClick={() => dashboardStore.showProfilePage(false)}
          >
            Log In
          </button>

          <Box className="w-full flex justify-center items-center">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#E31837",
                  height: "2px",
                  borderRadius: "10px 10px 0px 0px",
                }, // Change the indicator color to red
              }}
              sx={{
                // Custom style for selected tab
                "& .MuiTabs-indicator": {
                  backgroundColor: "red",
                },
                "& .MuiTab-root": {
                  color: "#737E93",
                  fontSize: "16px",
                  fontWeight: "500",
                  textTransform: "none",
                },
                "& .Mui-selected": {
                  color: "#E31837 !important",
                  textTransform: "none",
                },
                ".Mui-selected": {
                  color: "#E31837",
                  fontSize: "16px",
                  fontWeight: "500",
                  textTransform: "none",
                },
              }}
            >
              <Tab label="Email" {...a11yProps(1)} />
              <Tab label="Mobile Number" {...a11yProps(0)} />
            </Tabs>
          </Box>

          <CustomTabPanel value={value} index={0}>
            <div className="flex justify-center items-center w-full">
              <input
                type="text"
                name="emailId"
                value={studentDetails.emailId}
                onChange={handleOnChangeEmail}
                className="w-full h-7 py-6 px-4 rounded-full border border-red-600 focus:outline-none"
                placeholder="Email"
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onClickEmailHandler();
                  }
                }}
              />
            </div>
            <button
              className={`text-[#FFFFFF] ${
                loadingEmail || !studentDetails.emailId || !isValidEmail
                  ? "bg-gray-300 text-white"
                  : "bg-[#E31837] text-white"
              }  w-full h-12 border-[1px] rounded-3xl mt-10 mb-6 px-120`}
              onClick={onClickEmailHandler}
              disabled={
                loadingEmail ||
                loading ||
                !studentDetails.emailId ||
                !isValidEmail
              }
            >
              {loadingEmail ? (
                <div className="buttonLoader mx-auto"></div>
              ) : (
                "GET OTP"
              )}
            </button>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div className="flex justify-center items-center w-full">
              <PhoneInput
                containerClass="w-full"
                inputClass="h-12 border border-[1px] rounded-3xl border border-red-600 mb-4 px-2"
                country={"in"}
                placeholder=""
                value={studentDetails.mobileNumber}
                onChange={handleOnChange}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onClickHandler();
                  }
                }}
              />
            </div>
            <button
              className={`text-[#FFFFFF] ${
                loading || !studentDetails.mobileNumber || !isValid
                  ? "bg-gray-300 text-white"
                  : "bg-[#E31837] text-white"
              }  w-full h-12 border-[1px] rounded-3xl mt-10 mb-6 px-120`}
              onClick={onClickHandler}
              disabled={
                loading ||
                loadingEmail ||
                !studentDetails.mobileNumber ||
                !isValid
              }
            >
              {loading ? (
                <div className="buttonLoader mx-auto"></div>
              ) : (
                "GET OTP"
              )}
            </button>
          </CustomTabPanel>
          <p className="text-[#737E93] text-sm font-bold text-center mb-6">
            Or continue with
          </p>

          <div className="flex justify-center w-full">
            {/* <span className="mx-2">
            <img src={FacebookImage} alt="Facebook" />
          </span>
          <span className="mx-2">
            <img src={AppleImage} alt="Apple" />
          </span> */}
            <span className="mx-2">
              <button className="cursor-pointer" onClick={gmailHandler}>
                <img src={GoogleImage} alt="Google" />
              </button>
            </span>
          </div>
        </div>
      </div>
      <img
        src={BottomRightIcon}
        alt="right"
        className="absolute bottom-0 right-0"
      />
      <img
        src={BottomLeftIcon}
        alt="left"
        className="absolute bottom-0 left-0"
      />
    </>
  );
}

export default observer(Login);
